export default [
  {
    header: 'Quản trị',
  },
  {
    title: 'Thống kê',
    icon: 'LayersIcon',
    route: 'dashboard-general',
    resource: 'Dashboard',
    action: 'read',
  },

];
